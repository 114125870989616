
import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import deepEqual from "fast-deep-equal";
import parseHTML from "html-react-parser";
import FormItem from "../generic/form-item";
import EventLinks from "../generic/event-links";
import EventUtils from "../../utils/event-utils";
import strings from "../../localization/strings";
import EventTiming from "../generic/event-timing";
import EventLocation from "../generic/event-location";
import styles from "../../styles/event/event-add-form";
import LocalizedInput from "../generic/localized-input";
import { SnackbarMessage } from "../layouts/app-layout";
import SimpleReactValidator from "simple-react-validator";
import EventCategories from "../generic/event-categories";
import EventImagePicker from "../generic/event-image-picker";
import { EVENT_LINK_NAME } from "../../constants/action-types";
import LocalizedTextfield from "../generic/localized-textfield";
import EventOtherAttachments from "../generic/event-other-attachments";
import LocalizationCheckboxes from "../generic/localization-checkboxes";
import LocalizedRichTextField from "../generic/localized-richtextfield";
import EventContactInformation from "../generic/event-contact-information";
import { Checkbox, FormControlLabel, Typography, withStyles, WithStyles } from "@material-ui/core";
import { Attachment, CustomData, CustomStyles, EventPeriod, Localizations, LocalizedValues, NullableToken } from "../../types";
import { EventName, EventShortDescription, IdRef, Place, PostEvent, PostEventProvider, Image, OfferInfoUrl, OfferPrice, PostEventDescription } from "../../generated/client";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  title?: string;
  places: Place[];
  event: PostEvent;
  showHelp?: boolean;
  eventImage?: Image;
  eventLocation?: Place;
  placesLoaded?: boolean;
  eventImagePreview?: Image;
  eventPeriods: EventPeriod[];
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  localizations: Localizations;
  showValidationMessages: boolean;
  locale: string;
  onRemoveEventImage: () => void;
  onValidUpdate: (valid: boolean) => void;
  onEventUpdate: (event: PostEvent) => void;
  onPlaceUpdate?: (value: Place | null) => void;
  onChangeEventImage: (eventImage: Image) => void;
  setSnackbarMessage?: (message: SnackbarMessage) => void;
  onUpdateEventPeriods: (eventPeriods: EventPeriod[]) => void;
  onLocalizationUpdate: (localizations: Localizations) => void;
  onChangeEventImagePreview: (eventImagePreview: Image) => void;
}

/**
 * Interface describing component state
 */
interface State {
  descriptions: PostEventDescription;
}

/**
 * Component for event search form
 *
 * @param props component props
 */
class EventAddForm extends React.Component<Props, State> {

  /**
   * Validator for the form
   */
  private validator: SimpleReactValidator;

  /**
   * Boolean defining that form is valid
   */
  private formValid: boolean = false;

  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      descriptions: {
        fi: "",
        sv: "",
        en: ""
      }
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        url: strings.validator.invalidUrl,
        default: strings.validator.requiredField,
        max: strings.validator.keywords,
        min: strings.validator.dates
      },
      element: (message: string) => <p style={{ color: "red", margin: 0 }}>{ message }</p>
    });
  }

  /**
   * Component did mount
   *
   */
  public componentDidMount = () => {
    const { event } = this.props;

    this.validator.purgeFields();

    this.setState({
      descriptions: event.description || { fi: "", sv: "", en: "" }
    });
  }

  /**
   * Component did update
   *
   * @param prevProps previous props
   */
  public componentDidUpdate = async (prevProps: Props) => {
    if (prevProps.locale !== this.props.locale) {
      this.validator.purgeFields();
    }

    if (prevProps.showValidationMessages !== this.props.showValidationMessages && this.props.showValidationMessages) {
      this.validator.showMessages();
    }

    if (!deepEqual(prevProps.event, this.props.event) || !deepEqual(prevProps.eventPeriods, this.props.eventPeriods)) {
      const allValid = this.validator.allValid();

      if (this.formValid !== allValid) {
        this.formValid = allValid;
        this.props.onValidUpdate(this.formValid);
      }
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const {
      places,
      classes,
      showHelp,
      eventImage,
      eventPeriods,
      placesLoaded,
      customStyles,
      localizations,
      eventLocation,
      eventImagePreview,
      onUpdateEventPeriods
    } = this.props;

    const { event } = this.props;
    const { keywords } = event;
    const offer = this.getOffer();
    const provider = this.getProvider();
    const customData = this.getCustomData();
    const priceDescription = this.getPriceDescription();
    const externalLinks = this.getExternalLinksObject();
    const {
      provider_phone,
      provider_email,
      other_attachments,
      registration_description
    } = customData;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderTitle() }
        <FormItem title={ strings.eventForm.chooseLocalizations }>
          <LocalizationCheckboxes
            localizations={ localizations }
            onChange={ this.setLocalizations }
            localizedStrings={ strings.eventForm.languages }
          />
        </FormItem>
        <FormItem title={ strings.eventForm.eventNameHeading }>
          <LocalizedInput
            characterLimit={ 80 }
            required={ true }
            validator={ this.validator }
            localizations={ localizations }
            onChange={ this.setEventName }
            localizedStrings={ strings.eventForm.eventName }
            localizedValues={ event.name as LocalizedValues }
          />
        </FormItem>
        <FormItem title={ strings.eventForm.eventShortDescriptionHeading }>
          <LocalizedInput
            characterLimit={ 140 }
            required={ true }
            validator={ this.validator }
            localizations={ localizations }
            onChange={ this.setEventShortDescription }
            localizedStrings={ strings.eventForm.eventShortDescription }
            localizedValues={ event.shortDescription as LocalizedValues }
          />
        </FormItem>
        <FormItem title={ strings.eventForm.eventDescriptionHeading }>
          <LocalizedRichTextField
            localizations={ localizations }
            onChange={ this.setEventDescription }
            localizedStrings={ strings.eventForm.eventDescription }
            localizedValues={ event.description as LocalizedValues }
          />
        </FormItem>
        <FormItem
          title={ strings.eventForm.eventImage }
          showHelp={ showHelp }
          helpContent={ parseHTML(strings.eventForm.eventImageDescription) }
        >
          <EventImagePicker
            image={ eventImage }
            validator={ this.validator }
            onChange={ this.setEventImage }
            previewImage={ eventImagePreview }
            onRemoveImage={ this.removeEventImage }
            onSetPreviewImage={ this.setPreviewImage }
            showMessages={ this.validator.messagesShown }
          />
        </FormItem>
        <FormItem title={ strings.eventForm.otherAttachments }>
          <EventOtherAttachments
            onChange={ this.setOtherAttachments }
            onRemoveAttachment={ this.removeAttachment }
            attachments={ JSON.parse(other_attachments ? other_attachments : '[]') }
          />
        </FormItem>
        <FormItem
          title={ strings.eventForm.eventTiming }
          showHelp={ showHelp }
          helpContent={ parseHTML(strings.eventForm.eventTimingDescription) }
        >
          <EventTiming
            endValue={ event.endTime }
            validator={ this.validator }
            eventPeriods={ eventPeriods }
            startValue={ event.startTime }
            onEndTimeChange={ this.setEventEndDate }
            onStartTimeChange={ this.setEventStartDate }
            onUpdateEventPeriods={ onUpdateEventPeriods }
          />
        </FormItem>
        <FormItem
          showHelp={ showHelp }
          title={ strings.eventForm.eventLocation }
          helpContent={ parseHTML(strings.eventForm.eventLocationDescription)}
        >
          <EventLocation
            places={ places }
            validator={ this.validator }
            placesLoaded={ placesLoaded }
            localizations={ localizations }
            eventLocation={ eventLocation }
            onPlaceUpdate={ this.setEventLocation }
            showMessages={ this.validator.messagesShown }
            setSnackbarMessage={ this.setSnackbarMessage }
            onPlaceDescriptionUpdate={ this.setPlaceDescription }
            placeDescription={ event.locationExtraInfo as LocalizedValues }
          />
        </FormItem>
        <FormItem
          title={ this.getPriceHeading() }
          showHelp={ showHelp }
          helpContent={ parseHTML(strings.eventForm.eventPriceDescriptionHeadingDescription) }
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={ this.setEventIsFree }
                checked={ !!offer.isFree }
              />
            }
            label={ strings.eventForm.freeOfCharge }
          />
          <LocalizedTextfield
            characterLimit={ 500 }
            required={ !offer.isFree }
            validator={ this.validator }
            localizations={ localizations }
            onChange={ this.setEventPriceDescription }
            localizedValues={ priceDescription as LocalizedValues }
            localizedStrings={ strings.eventForm.eventPriceDescription }
          />
        </FormItem>
        <FormItem
          title={ strings.eventForm.onlineTicketSales }
          showHelp={ showHelp }
        >
          <LocalizedTextfield
            characterLimit={ 500 }
            localizations={ localizations }
            onChange={ this.setEventOnlineTicketLink }
            localizedValues={ offer.infoUrl as LocalizedValues }
            localizedStrings={ strings.eventForm.onlineTicketSalesLink }
          />
        </FormItem>
        <FormItem
          title={ strings.eventForm.eventRegistration }
          showHelp={ showHelp }
          helpContent={ parseHTML(strings.eventForm.eventRegistrationDescriptionInfo) }
        >
          <LocalizedTextfield
            characterLimit={ 500 }
            localizations={ localizations }
            onChange={ this.setEventRegistrationDescription }
            localizedStrings={ strings.eventForm.eventRegistrationDescription }
            localizedValues={ JSON.parse(registration_description || `{ "fi": "", "sv": "", "en": "" }`) }
          />
        </FormItem>
        <FormItem
          title={ strings.eventForm.eventLinksHeading }
          showHelp={ showHelp }
          helpContent={ parseHTML(strings.eventForm.eventLinksHeadingDescription) }
        >
          <EventLinks
            validator={ this.validator }
            externalLinks={ externalLinks }
            onChange={ this.setExternalLinks }
          />
        </FormItem>
        <FormItem>
          <EventCategories
            showHelp={ showHelp }
            keywords={ keywords }
            validator={ this.validator }
            onChange={ this.setKeywords }
            showMessages={ this.validator.messagesShown }
          />
        </FormItem>
        <FormItem title={ strings.eventForm.eventContactInformation }>
          <EventContactInformation
            showHelp={ showHelp }
            validator={ this.validator }
            localizations={ localizations }
            providerName={ provider }
            onChangeProviderName={ this.setEventProviderName }
            providerPhone={ provider_phone }
            onChangeProviderPhone={ this.setEventProviderPhone }
            providerEmail={ provider_email }
            onChangeProviderEmail={ this.setEventProviderEmail }
          />
        </FormItem>
      </div>
    );
  }

  /**
   * Renders form title
   */
  private renderTitle = () => {
    const { classes, customStyles, title } = this.props;

    if (!title) {
      return null;
    }

    return (
      <Typography
        variant="h1"
        className={ classes.title }
        style={ customStyles?.title }
      >
        { title }
      </Typography>
    );
  }

  /**
   * Updates event state
   *
   * @param event even
   */
  private updateEvent = (event: PostEvent) => {
    const { onEventUpdate } = this.props;

    onEventUpdate(event);
  }

  /**
   * Method for getting provider
   *
   * @returns event provider
   */
  private getProvider = (): PostEventProvider => {
    const { event } = this.props;
    const { provider } = event;

    if (!provider) {
      return {
        fi: "",
        sv: "",
        en: ""
      }
    }

    return provider;
  }

  /**
   * Method for getting offer
   *
   * @returns offer
   */
  private getOffer = () => {
    const { event } = this.props;
    const offer = event.offers![0];

    if (!offer.infoUrl) {
      offer.infoUrl = {
        fi: "",
        sv: "",
        en: ""
      }
    }

    return offer;
  }

  /**
   * Method for getting custom data
   *
   * @returns custom data object
   */
  private getCustomData = (): CustomData => {
    const { event } = this.props;
    return {
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      provider_phone: "",
      provider_email: "",
      other_attachments: "",
      registration_description: `{ "fi": "", "sv": "", "en": "" }`,
      ...event.customData
    };
  }

  /**
   * Method for getting external links
   *
   * @returns external link array
   */
  private getExternalLinks = () => {
    const { event } = this.props;

    const links = [
      {
        name: "YouTube",
        link: "",
        language: "fi"
      },
      {
        name: "Twitter",
        link: "",
        language: "fi"
      },
      {
        name: "Facebook",
        link: "",
        language: "fi"
      },
      {
        name: "Instagram",
        link: "",
        language: "fi"
      },
      {
        name: "Instagram HashTag",
        link: "",
        language: "fi"
      },
      {
        name: "YouTube Or Vimeo Link",
        link: "",
        language: "fi"
      },
      {
        name: "Event Organizer Link",
        link: "",
        language: "fi"
      }
    ];

    return links.map((eventLink) => {
      const externalLink = event.externalLinks?.find((item) => item.name === eventLink.name);

      return {
        language: "fi",
        name: eventLink.name,
        link: externalLink && externalLink.link ? externalLink.link : eventLink.link
      };
    });
  }

  /**
   * Method for getting external links object
   *
   * @returns external links hashmap
   */
  private getExternalLinksObject = () => {
    const externalLinks = this.getExternalLinks();
    const result: { [key: string]: string } = {};

    EVENT_LINK_NAME.forEach((name) => {
      const externalLink = externalLinks.find(link => link.name === name);

      result[name] = externalLink?.link || "";
    });

    return result;
  }

  /**
   * Method for getting price heading
   */
  private getPriceHeading = (): string => {
    const { event } = this.props;
    const offer = event.offers![0];
    const { isFree } = offer;

    return strings.formatString(strings.eventForm.eventPriceDescriptionHeading, isFree ? "" : "*") as string;
  }

  /**
   * Method for getting price description
   *
   * Note: Price description is written to price
   * field to keep old event data compatible
   *
   * @returns offer description
   */
  private getPriceDescription = (): OfferPrice => {
    const { event } = this.props;
    const { offers } = event;

    if (!offers || !offers[0].price) {
      return { fi: "", sv: "", en: "" };
    }

    return offers[0].price;
  }

  /**
   * Returns event object
   *
   * @param key key
   * @param value value
   */
  private setExternalLinks = (key: string, value: string) => {
    const { event } = this.props;
    const externalLinks = this.getExternalLinks();

    const index = externalLinks.findIndex(link => link.name === key);

    if (index === -1) {
      return;
    }

    externalLinks[index].link = value;

    const updatedEvent = {
      ...event,
      externalLinks: [...externalLinks]
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting localizations
   *
   * @param locale locale
   */
  private setLocalizations = (locale: string) => async () => {
    const { localizations, onLocalizationUpdate } = this.props;

    const localizationKeys = EventUtils.getLocalizationKeys();
    const activeLocalizations = localizationKeys.filter(key => localizations[key as keyof Localizations]);

    if (activeLocalizations.length === 1 && locale === activeLocalizations[0]) {
      return;
    }

    localizations[locale as keyof Localizations] = !localizations[locale as keyof Localizations];

    this.removeInactiveLocalizations();
    this.validator.purgeFields();
    onLocalizationUpdate(localizations);
  }

  /**
   * Method for updating localizations
   */
  private removeInactiveLocalizations = () => {
    const { localizations } = this.props;

    const localizationKeys = EventUtils.getLocalizationKeys();
    const inactiveLocalizations = localizationKeys.filter(key => !localizations[key as keyof Localizations]);

    inactiveLocalizations.forEach(inactiveLocalization => {
      this.setEventName(inactiveLocalization, "");
      this.setEventDescription(inactiveLocalization, "");
      this.setEventShortDescription(inactiveLocalization, "");
      this.setEventPriceDescription(inactiveLocalization, "");
      this.setEventRegistrationDescription(inactiveLocalization, "");
    });
  }

  /**
   * Method for setting event name
   *
   * @param locale locale
   * @param value value
   */
  private setEventName = (locale: string, value: string) => {
    const { event } = this.props;

    if (!event.name) {
      return;
    }

    const updatedEvent = {
      ...event,
      name: {
        ...event.name,
        [locale as keyof EventName]: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event short description
   */
  private setEventShortDescription = (locale: string, value: string) => {
    const { event } = this.props;

    if (!event.shortDescription) {
      return;
    }

    const updatedEvent = {
      ...event,
      shortDescription: {
        ...event.shortDescription,
        [locale as keyof EventShortDescription]: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event description
   *
   * @param locale locale
   * @param value value
   */
  private setEventDescription = (locale: string, value: string) => {
    const { event } = this.props;
    const { descriptions } = this.state;

    if (!descriptions) {
      return;
    }

    this.setState(
      (prevState) => ({
        descriptions: {
          ...prevState.descriptions,
          [locale]: value
        },
      }),
      () => {
        const updatedDescriptions = this.state.descriptions;

        const updatedEvent = {
          ...event,
          description: updatedDescriptions
        };

        this.updateEvent(updatedEvent);
      }
    );
  }

  /**
   * Method for setting event location
   *
   * @param place place that will be set for the event
   */
  private setEventLocation = (place: Place) => {
    const { onPlaceUpdate } = this.props;
    const { event } = this.props;

    const updatedEvent = {
      ...event,
      location: place
    };

    this.updateEvent(updatedEvent);
    onPlaceUpdate && onPlaceUpdate(place);
  }

  /**
   * Method for setting place description
   *
   * @param locale locale
   * @param value value
   */
  private setPlaceDescription = (locale: string, value: string) => {
    const { event } = this.props;

    const updatedEvent = {
      ...event,
      locationExtraInfo: {
        ...event.locationExtraInfo,
        [locale]: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method setting event image
   *
   * @param image image
   */
  private setEventImage = async (image: Image) => {
    this.props.onChangeEventImage(image);
  }

  /**
   * Method for setting preview image
   *
   * @param name image name
   * @param url image url
   */
  private setPreviewImage = (name: string, url: string) => {
    const { onChangeEventImagePreview } = this.props;

    const previewImage: Image = {
      name: name,
      url: url
    };

    onChangeEventImagePreview(previewImage);
  }

  /**
   * Method for removing event image
   */
  private removeEventImage = () => {
    this.props.onRemoveEventImage();
  }

  /**
   * Method for setting other attachments
   *
   * @param attachments attachments
   */
  private setOtherAttachments = (attachments: Attachment[]) => {
    const { event } = this.props;
    const customData = this.getCustomData();
    const { other_attachments } = customData;
    const otherAttachments = JSON.parse(other_attachments);

    const updatedEvent = {
      ...event,
      customData: {
        ...customData,
        other_attachments: JSON.stringify([
          ...otherAttachments,
          ...attachments
        ])
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for removing attachment
   *
   * @param index index
   */
  private removeAttachment = (index: number) => {
    const { event } = this.props;
    const customData = this.getCustomData();
    const otherAttachments = JSON.parse(customData.other_attachments);

    otherAttachments.splice(index, 1);

    const updatedEvent = {
      ...event,
      customData: {
        ...customData,
        other_attachments: JSON.stringify(otherAttachments)
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event start date
   *
   * @param date date
   */
  private setEventStartDate = (date: Date) => {
    const { event } = this.props;

    if (!event.startTime || !date) {
      return;
    }

    const updatedEvent = {
      ...event,
      startTime: date
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event end date
   *
   * @param date
   */
  private setEventEndDate = (date: Date) => {
    const { event } = this.props;

    if (!event.endTime || !date) {
      return;
    }

    const updatedEvent = {
      ...event,
      endTime: date
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event is free
   *
   * @param changeEvent change event
   * @param checked checked
   */
  private setEventIsFree = (changeEvent: React.ChangeEvent<any>, checked: boolean) => {
    const { event } = this.props;

    const { offers } = event;

    if (!offers || offers.length === 0) {
      return;
    }

    const offer = offers[0];

    if (!event.offers) {
      return;
    }

    offer.isFree = checked;

    const updatedEvent = {
      ...event,
      offers: [offer]
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event price description
   *
   * Note: Price description is written to price
   * field to keep old event data compatible
   *
   * @param locale locale
   * @param value value
   */
  private setEventPriceDescription = (locale: string, value: string) => {
    const { event } = this.props;
    const { offers } = event;

    if (!offers || !offers.length) {
      return;
    }

    const offer = {...offers[0]};

    if (!offer.price) {
      offer.price = {
        fi: "",
        sv: "",
        en: ""
      };
    }

    const updatedEvent = {
      ...event,
      offers: [
        {
          ...offer,
          price: {
            ...offer.price,
            [locale as keyof OfferPrice]: value
          }
        }
      ]
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event online ticket link
   *
   * @param locale locale
   * @param value value
   */
  private setEventOnlineTicketLink = (locale: string, value: string) => {
    const { event } = this.props;
    const { offers } = event;

    if (!offers || !offers.length || !event.offers) {
      return;
    }

    const offer = {...offers[0]};

    if (!offer.infoUrl) {
      return;
    }

    const updatedEvent = {
      ...event,
      offers: [
        {
          ...offer,
          infoUrl: {
            ...offer.infoUrl,
            [locale as keyof OfferInfoUrl]: value
          }
        }
      ]
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event registration description
   *
   * @param locale locale
   * @param value value
   */
  private setEventRegistrationDescription = (locale: string, value: string) => {
    const { event } = this.props;
    const customData = this.getCustomData();

    const updatedEvent = {
      ...event,
      customData: {
        ...customData,
        registration_description: JSON.stringify({
          ...JSON.parse(customData.registration_description),
          [locale]: value
        })
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting keywords
   *
   * @param keywords keywords
   */
  private setKeywords = (keywords: IdRef[]) => {
    const { event } = this.props;

    const updatedEvent = {
      ...event,
      keywords: keywords
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event provider name
   *
   * @param value value
   */
  private setEventProviderName = (locale: string, value: string) => {
    const { event } = this.props;

    const updatedEvent = {
      ...event,
      provider: {
        ...event.provider,
        [locale]: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event provider phone
   *
   * @param value value
   */
  private setEventProviderPhone = (value: string) => {
    const { event } = this.props;
    const customData = this.getCustomData();

    const updatedEvent = {
      ...event,
      customData: {
        ...customData,
        provider_phone: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting event provider email
   *
   * @param value value
   */
  private setEventProviderEmail = (value: string) => {
    const { event } = this.props;
    const customData = this.getCustomData();

    const updatedEvent = {
      ...event,
      customData: {
        ...customData,
        provider_email: value
      }
    };

    this.updateEvent(updatedEvent);
  }

  /**
   * Method for setting snackbar message
   *
   * @param message snackbar message
   */
  private setSnackbarMessage = (message: SnackbarMessage) => {
    const { setSnackbarMessage } = this.props;

    if (!setSnackbarMessage) {
      return;
    }

    setSnackbarMessage(message);
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
});

const Styled = withStyles(styles)(EventAddForm);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
